<template>
  <div>
    <div class="card">
      <!-- LOG TABLE -->
      <DataTable
        ref="dt"
        class="p-datatable-sm"
        :value="this.$store.getters.getSystemLog"
        dataKey="id"
        :paginator="true"
        :rows="50"
        :filters="filters"
        sortField="timestamp"
        :sortOrder="-1"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[10, 50, 100, 500]"
        currentPageReportTemplate="zeige {first} bis {last} von {totalRecords} LOG-Einträgen"
        responsiveLayout="stack"
        :loading="loading"
      >
        <template #loading> Daten werden geladen... bitte warten </template>
        <template #header>
          <div class="table-header flex justify-content-between">
            <h5 class="mb-1">System Log</h5>
            <span class="p-input-icon-left">
              <i class="pi pi-search" />
              <InputText
                v-model="filters['global'].value"
                placeholder="suchen..."
              />
            </span>
          </div>
        </template>

        <Column
          field="timestamp"
          header="Timestamp"
          :sortable="true"
          style="min-width: 10rem"
        >
          <template #body="slotProps">
            {{ formatDate(slotProps.data.timestamp) }}
          </template>
        </Column>
        <Column
          field="category"
          header="Kategorie"
          :sortable="true"
          style="min-width: 8rem"
        ></Column>
        <Column
          field="message.type"
          header="Type"
          :sortable="true"
          style="min-width: 4rem"
        >
        </Column>
        <Column field="message.text" header="Meldung" :sortable="true"></Column>
      </DataTable>
    </div>
  </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
import { formatDateLog } from '@/helpers';
import mainService from "@/services/mainService.js";

export default {
  name: "systemLog",
  data() {
    return {
      loading: false,
      system_log: null,
      filters: {},
      submitted: false,
    };
  },
  created() {
    this.initFilters();
  },
  mounted() {
    this.loading = true;
    this.getParameter();
  },
  methods: {
    getParameter() {
      this.loading = true;
      mainService
        .getConfigIOData()
        .then((response) => {
          // console.log('ConfigIO: ', response.data)
          this.io_data = response.data.io_data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
    formatDate(timestamp) {
      return formatDateLog(timestamp);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>